/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , { Component } from 'react';
import {
 Link
} from 'react-router-dom';

// reactstrap components
import { Button, Container } from "reactstrap";
import classnames from "classnames";

// core components

function HomeHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundColor: "white"
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          {/* <div className="motto text-center">
            <img
              alt="logo-membership"
              className={classnames("membership-logo-motto")}
              src={require('./../../../assets/img/niec-logo/Logo_Member-01.png')}
            />
          </div> */}
            <div className="row">
                <div className="col-lg-4 offset-lg-2 text-center bg-content-1-container">
                    <div className="bg-content-1">
                        <img src={require('./../../../assets/img/niec-logo/Logo_Member-01.png')} className="img-fluid" alt="" />
                    </div>
                </div>
                                    
                <div className="col-lg-5 bg-content-3-container">
                    <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt' }}>Selamat datang di NIEC Prioritas member</h4> 
                    <p className="description" style={{ fontWeight: '500', color:'black' }}>
                        Sebuah program keanggotaan yang bersifat ekslusif bagi siapapun yang memiliki keinginan 
                        untuk melanjutkan pendidikan keluar negeri. Dapatkan konsultasi berangkat keluar negeri 
                        dan berbagai akses ekslusif ke beberapa event NIEC Indonesia.
                    </p>
                </div>
            </div>
        </Container>
      </div>
    </>
  );
}

export default HomeHeader;
