/** @format */
import _LandingPage from './LandingPage';
import _Home from './Home';
import _Login from './Login';
import _Logout from './Logout';

export const LandingPage = _LandingPage;
export const Home = _Home;
export const Login = _Login;
export const Logout = _Logout;
