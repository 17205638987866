/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
import {
  Link
} from 'react-router-dom';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import classnames from "classnames";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-bootstrap/Carousel';
import API_URL from './../../../ApiUrl';
import banner1 from '../../../assets/img/banner/pic1_1630035748.1.png';


// core components

function LandingPageHeader(props) {
  // console.log('ppp', props.datakonten.pic1)
  let pageHeader = React.createRef();
  var Img1 = {
    backgroundImage: "url(" + banner1 + ")",
  }
  var Img2 = {
    backgroundImage: "url(" + API_URL.url + '/uploads/uploads/' + props.datakonten.id_konten + '/' + props.datakonten.pic2 + ")"
  }
  var Img3 = {
    backgroundImage: "url(" + API_URL.url + '/uploads/uploads/' + props.datakonten.id_konten + '/' + props.datakonten.pic3 + ")"
  }
  var Img4 = {
    backgroundImage: "url(" + API_URL.url + '/uploads/uploads/' + props.datakonten.id_konten + '/' + props.datakonten.pic4 + ")"
  }

  React.useEffect(() => {
    if (window.innerWidth < 8) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 9;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var mystyle = { position: 'absolute', display: 'none' };
  //var mystyle2 = { position: 'relative',height: '400px',padding: '0'};
  var msytyle3 = { position: 'absolute', width: '100%', height: '100%' };

  const responsiveBanner = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <Carousel>
        <Carousel.Item style={Img1} className="responsiveImg">
          <Carousel.Caption>
            <img style={{ marginTop: '-5%', maxWidth: '270px', maxHeight: '170px', marginLeft: '90%'}}
              alt="..."
              src={require("../../../assets/img/niec-logo/Icon-header.png")}
            />
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default LandingPageHeader;
