/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Cookies from 'universal-cookie';
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';
import { DataGrid, GridColumn, Form as FormEasyui, Dialog, TextBox, NumberBox, Label as LabelEasyui, LinkButton, ButtonGroup, CheckBox, Tooltip } from 'rc-easyui';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormGroup,
  Label,

} from "reactstrap";
import { StyleRoot } from 'radium';

import ReactDOM from 'react-dom';
import Coverflow from 'react-coverflow';

import { HomeHeader, PaymentNavbar, DemoFooter } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import API_URL from "../../../ApiUrl";

const cookies = new Cookies();
class Home extends Component {
  constructor(props) {
    super(props);
    if (!cookies.get('token')) {
      window.location.href = "/";
    }
    let base64 = require('base-64');
    this.state = {
      id_rep: base64.decode(cookies.get('token')),
      selectedFile1: "",
      selectedFile2: "",
      selectedFile3: "",
      selectedFile4: "",
      selectedFile5: "",
      selectedFile6: "",
      dataKonten: [],
      isOpenImgVwr: false,
      pic1: "",
      pic2: "",
      pic3: "",
      pic4: "",
      pic5: "",
      pic5: "",
      disable1: true,
      disable2: true,
      disable3: true,
      disable4: true,
      disable5: true,
      disable6: true,
      buttonStatus1: "UPLOAD",
      buttonStatus2: "UPLOAD",
      buttonStatus3: "UPLOAD",
      buttonStatus4: "UPLOAD",
      buttonStatus5: "UPLOAD",
      buttonStatus6: "UPLOAD",
      data: [],
      editingRow: null,
      model: {},
      rules: {
        // 'id_konten': 'required',
        // 'judul': ['required', 'length[5,10]']
      },
      errors: {},
      title: '',
      closed: true,
      deleteModal: true,
      modalImages: true

      // harga:[]
    }
  }

  getError(name) {
    const { errors } = this.state;
    if (!errors) {
      return null;
    }
    return errors[name] && errors[name].length
      ? errors[name][0]
      : null;
  }
  AddRow(row) {
    this.setState({
      title: 'Add',
      closed: false,
      editingRow: null,
      model: {},
    });
  }
  editRow(row) {
    this.setState({
      editingRow: row,
      model: Object.assign({}, row),
      title: 'Edit',
      closed: false
    });
  }
  imagesRow(row) {
    this.setState({
      editingRow: row,
      model: Object.assign({}, row),
      title: 'Images',
      modalImages: false
    });
  }
  deleteRow(row) {
    this.setState({
      model: Object.assign({}, row),
      title: 'Delete',
      deleteModal: false
    });
  }
  saveRow() {
    this.form.validate(() => {
      // let row = Object.assign({}, this.state.editingRow, this.state.model);
      // console.log('edit', row)
      if (this.form.valid()) {
        let row = Object.assign({}, this.state.editingRow, this.state.model);
        // let data = this.state.data.slice();
        // let index = data.indexOf(this.state.editingRow);
        // let newData = data.splice(index, 1, row);
        const formData = new FormData();
        formData.append('id_konten', row.id_konten);
        formData.append('judul', row.judul);
        formData.append('text_konten', row.text_konten);
        formData.append('status', row.status);

        axios({
          method: 'POST',
          url: API_URL.url + "/konten/update",
          data: formData,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.isMakassarKey}`
          }
        }).then((reply) => {
          console.log(reply);
          if (reply.data[0].status === "ok") {
            this.getDataKonten();
            this.setState({
              // data: data,
              closed: true
            })
            // alert('Success');
            // window.location.href = "/home";
          } else {
            alert('Failed');
          }
        }).catch(function (reply) {
          console.log(reply);
        })

        this.setState({
          // data: data,
          closed: true
        })


      }
    })
  }

  saveAddRow() {
    this.form.validate(() => {
      //let row = Object.assign({}, this.state.editingRow, this.state.model);
      // console.log('add', this.state.model)
      if (this.form.valid()) {
        let row = Object.assign({}, this.state.editingRow, this.state.model);
        // let data = this.state.data.slice();
        // let index = data.indexOf(this.state.editingRow);
        // let newData = data.splice(index, 1, row);
        const formData = new FormData();
        formData.append('judul', row.judul);
        formData.append('text_konten', row.text_konten);

        axios({
          method: 'POST',
          url: API_URL.url + "/konten/add",
          data: formData,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.isMakassarKey}`
          }
        }).then((reply) => {
          console.log(reply);
          if (reply.data[0].status === "ok") {
            this.getDataKonten();
            this.setState({
              // data: data,
              closed: true
            })
            // alert('Success');
            // window.location.href = "/home";
          } else {
            alert('Failed');
          }
        }).catch(function (reply) {
          console.log(reply);
        })

        this.setState({
          // data: data,
          closed: true
        })


      }
    })
  }

  saveDeleteRow() {
    // this.setState({
    //   data: this.state.data.filter(r => r !== row)
    // })

    let row = Object.assign({}, this.state.editingRow, this.state.model);
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);

    axios({
      method: 'POST',
      url: API_URL.url + "/konten/hapus",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data[0].status === "ok") {
        this.getDataKonten();
        this.setState({
          // data: data,
          deleteModal: true
        })
        // alert('Success');
        // window.location.href = "/home";
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })

    this.setState({
      // data: data,
      closed: true
    })

  }
  renderDialog() {
    const row = this.state.model;
    const { title, closed, rules } = this.state;
    if (title === 'Edit') {
      return (
        <Dialog modal title={title} closed={closed} onClose={() => this.setState({ closed: true })}>
          <div className="f-full" style={{ padding: '20px 50px' }}>
            <FormEasyui className="f-full"
              ref={ref => this.form = ref}
              model={row}
              rules={rules}
              onValidate={(errors) => this.setState({ errors: errors })}
            >
              <div style={{ marginBottom: 10 }}>
                <LabelEasyui htmlFor="judul">Judul:</LabelEasyui>
                <TextBox inputId="judul" name="judul" value={row.judul} style={{ width: 220 }}></TextBox>
                <div className="error">{this.getError('judul')}</div>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <LabelEasyui htmlFor="text_konten" align="top">Text Konten:</LabelEasyui>
                <TextBox inputId="text_konten" name="text_konten" multiline value={row.text_konten} style={{ width: '100%', height: 120 }}></TextBox>
                <div className="error">{this.getError('text_konten')}</div>
              </div>
              <div style={{ marginBottom: 10 }}>
                <LabelEasyui htmlFor="status">Status:</LabelEasyui>
                <TextBox inputId="status" name="status" value={row.status} style={{ width: 220 }}></TextBox>
                <div className="error">{this.getError('status')}</div>
              </div>
            </FormEasyui>
          </div>
          <div className="dialog-button">
            <LinkButton style={{ width: 80 }} onClick={() => this.saveRow()}>Save</LinkButton>
            <LinkButton style={{ width: 80 }} onClick={() => this.setState({ closed: true })}>Close</LinkButton>
          </div>
        </Dialog>
      )
    } else {
      return (
        <Dialog modal title={title} closed={closed} onClose={() => this.setState({ closed: true })}>
          <div className="f-full" style={{ padding: '20px 50px' }}>
            <FormEasyui className="f-full"
              ref={ref => this.form = ref}
              model={row}
              rules={rules}
              onValidate={(errors) => this.setState({ errors: errors })}
            >
              <div style={{ marginBottom: 10 }}>
                <LabelEasyui htmlFor="judul">Judul:</LabelEasyui>
                <TextBox inputId="judul" name="judul" value={row.judul} style={{ width: 220 }}></TextBox>
                <div className="error">{this.getError('judul')}</div>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <LabelEasyui htmlFor="text_konten" align="top">Text Konten:</LabelEasyui>
                <TextBox inputId="text_konten" name="text_konten" multiline value={row.text_konten} style={{ width: '100%', height: 120 }}></TextBox>
                <div className="error">{this.getError('text_konten')}</div>
              </div>
            </FormEasyui>
          </div>
          <div className="dialog-button">
            <LinkButton style={{ width: 80 }} onClick={() => this.saveAddRow()}>Save</LinkButton>
            <LinkButton style={{ width: 80 }} onClick={() => this.setState({ closed: true })}>Close</LinkButton>
          </div>
        </Dialog>
      )
    }
  }
  renderDelete() {
    const { title, deleteModal, rules } = this.state;
    return (
      <Dialog modal title={title} closed={deleteModal} onClose={() => this.setState({ closed: true })}>
        <div className="f-full" style={{ padding: '20px 50px' }}>
          Hapus Data?
        </div>
        <div className="dialog-button">
          <LinkButton style={{ width: 80 }} onClick={() => this.saveDeleteRow()}>Save </LinkButton>
          <LinkButton style={{ width: 80 }} onClick={() => this.setState({ deleteModal: true })}>Close </LinkButton>
        </div>
      </Dialog>
    )
  }
  renderModalImages() {
    const row = this.state.model;
    const { title, modalImages, rules } = this.state;
    let base64 = require('base-64');
    let uploadDiv1;
    let uploadText1;
    let uploadDiv2;
    let uploadText2;
    let uploadDiv3;
    let uploadText3;
    let uploadDiv4;
    let uploadText4;
    let uploadDiv5;
    let uploadText5;
    let uploadDiv6;
    let uploadText6;

    if (row.pic1 !== null || row.pic1 !== '') {
      uploadText1 = "Edit";
      uploadDiv1 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} src={API_URL.url + "/uploads/uploads/" + row.id_konten + "/" + row.pic1} />
            </div>
          </div>
        </div>
      );
    } else {
      uploadText1 = "Upload";
      uploadDiv1 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} />
            </div>
          </div>
        </div>
      );
    }

    if (row.pic2 !== null || row.pic2 !== '') {
      uploadText2 = "Edit";
      uploadDiv2 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} src={API_URL.url + "/uploads/uploads/" + row.id_konten + "/" + row.pic2} />
            </div>
          </div>
        </div>
      );
    } else {
      uploadText2 = "Upload";
      uploadDiv2 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title1" style={{ fontWeight: 'bold', fontSize:'15pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} />
            </div>
          </div>
        </div>
      );
    }

    if (row.pic3 !== null || row.pic3 !== '') {
      uploadText3 = "Edit";
      uploadDiv3 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title3" style={{ fontWeight: 'bold', fontSize:'35pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} src={API_URL.url + "/uploads/uploads/" + row.id_konten + "/" + row.pic3} />
            </div>
          </div>
        </div>
      );
    } else {
      uploadText3 = "Upload";
      uploadDiv3 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title3" style={{ fontWeight: 'bold', fontSize:'35pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} />
            </div>
          </div>
        </div>
      );
    }

    if (row.pic4 !== null || row.pic4 !== '') {
      uploadText4 = "Edit";
      uploadDiv4 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title4" style={{ fontWeight: 'bold', fontSize:'45pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} src={API_URL.url + "/uploads/uploads/" + row.id_konten + "/" + row.pic4} />
            </div>
          </div>
        </div>
      );
    } else {
      uploadText4 = "Upload";
      uploadDiv4 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title4" style={{ fontWeight: 'bold', fontSize:'45pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} />
            </div>
          </div>
        </div>
      );
    }

    if (row.pic5 !== null || row.pic5 !== '') {
      uploadText5 = "Edit";
      uploadDiv5 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title4" style={{ fontWeight: 'bold', fontSize:'45pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} src={API_URL.url + "/uploads/uploads/" + row.id_konten + "/" + row.pic5} />
            </div>
          </div>
        </div>
      );
    } else {
      uploadText5 = "Upload";
      uploadDiv5 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title4" style={{ fontWeight: 'bold', fontSize:'45pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} />
            </div>
          </div>
        </div>
      );
    }

    if (row.pic6 !== null || row.pic6 !== '') {
      uploadText6 = "Edit";
      uploadDiv6 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title4" style={{ fontWeight: 'bold', fontSize:'45pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} src={API_URL.url + "/uploads/uploads/" + row.id_konten + "/" + row.pic6} />
            </div>
          </div>
        </div>
      );
    } else {
      uploadText6 = "Upload";
      uploadDiv6 = (
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="title4" style={{ fontWeight: 'bold', fontSize:'45pt', color:"black" }}>Payment Receipt : </h4> */}
            <div className="bukti-payment-container">
              <img style={{ maxWidth: "200px" }} />
            </div>
          </div>
        </div>
      );
    }
    return (
      <Dialog modal title={title} closed={modalImages} onClose={() => this.setState({ modalImages: true })}>
        <div className="f-full" style={{ padding: '20px 50px' }}>
          <form onSubmit={e => this.onFormSubmit1(e)} encType="multipart/form-data" >
            <h3 style={{ paddingBottom: "10px" }}>1. Images 1</h3>
            <Row>
              <Col md="">
                {uploadDiv1}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                {/* <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/> */}
                {/* <h5 style={{color:'#fff',marginTop:'20px'}}> Bukti Pembayaran</h5> */}
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input type="file" className="form-control" onChange={this.fileSelect1} />

                </InputGroup>
              </Col>
              <Col md="4">
                <Button block disabled={this.state.disable1} className="btn-round bgcolorgold" type="submit" >
                  {this.state.buttonStatus1}
                </Button>
              </Col>
            </Row>
          </form>

          <form onSubmit={e => this.onFormSubmit2(e)} encType="multipart/form-data" >
            <h3 style={{ paddingBottom: "10px" }}>2. Images 2</h3>
            <Row>
              <Col md="">
                {uploadDiv2}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                {/* <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/> */}
                {/* <h5 style={{color:'#fff',marginTop:'20px'}}> Bukti Pembayaran</h5> */}
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input type="file" className="form-control" onChange={this.fileSelect2} />

                </InputGroup>
              </Col>
              <Col md="4">
                <Button block disabled={this.state.disable2} className="btn-round bgcolorgold" type="submit" >
                  {this.state.buttonStatus2}
                </Button>
              </Col>
            </Row>
          </form>

          <form onSubmit={e => this.onFormSubmit3(e)} encType="multipart/form-data" >
            <h3 style={{ paddingBottom: "10px" }}>3. Images 3</h3>
            <Row>
              <Col md="">
                {uploadDiv3}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                {/* <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/> */}
                {/* <h5 style={{color:'#fff',marginTop:'30px'}}> Bukti Pembayaran</h5> */}
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input type="file" className="form-control" onChange={this.fileSelect3} />

                </InputGroup>
              </Col>
              <Col md="4">
                <Button block disabled={this.state.disable3} className="btn-round bgcolorgold" type="submit" >
                  {this.state.buttonStatus3}
                </Button>
              </Col>
            </Row>
          </form>

          <form onSubmit={e => this.onFormSubmit4(e)} encType="multipart/form-data" >
            <h3 style={{ paddingBottom: "10px" }}>4. Images 4</h3>
            <Row>
              <Col md="">
                {uploadDiv4}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                {/* <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/> */}
                {/* <h5 style={{color:'#fff',marginTop:'30px'}}> Bukti Pembayaran</h5> */}
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input type="file" className="form-control" onChange={this.fileSelect4} />

                </InputGroup>
              </Col>
              <Col md="4">
                <Button block disabled={this.state.disable4} className="btn-round bgcolorgold" type="submit" >
                  {this.state.buttonStatus4}
                </Button>
              </Col>
            </Row>
          </form>

          <form onSubmit={e => this.onFormSubmit5(e)} encType="multipart/form-data" >
            <h3 style={{ paddingBottom: "10px" }}>5. Images 5</h3>
            <Row>
              <Col md="">
                {uploadDiv5}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                {/* <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/> */}
                {/* <h5 style={{color:'#fff',marginTop:'30px'}}> Bukti Pembayaran</h5> */}
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input type="file" className="form-control" onChange={this.fileSelect5} />

                </InputGroup>
              </Col>
              <Col md="4">
                <Button block disabled={this.state.disable5} className="btn-round bgcolorgold" type="submit" >
                  {this.state.buttonStatus5}
                </Button>
              </Col>
            </Row>
          </form>

          <form onSubmit={e => this.onFormSubmit6(e)} encType="multipart/form-data" >
            <h3 style={{ paddingBottom: "10px" }}>6. Images 6</h3>
            <Row>
              <Col md="">
                {uploadDiv6}
              </Col>
            </Row>
            <Row>
              <Col md="8">
                {/* <input type="hidden" value={this.state.id_member} ref={e=>this.inputKodeMember=e}/> */}
                {/* <h5 style={{color:'#fff',marginTop:'30px'}}> Bukti Pembayaran</h5> */}
                <InputGroup className="form-group-no-border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input type="file" className="form-control" onChange={this.fileSelect6} />

                </InputGroup>
              </Col>
              <Col md="4">
                <Button block disabled={this.state.disable6} className="btn-round bgcolorgold" type="submit" >
                  {this.state.buttonStatus6}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
        <div className="dialog-button">
          {/* <LinkButton style={{ width: 80 }} onClick={() => this.saveAddRow()}>Save</LinkButton> */}
          <LinkButton style={{ width: 80 }} onClick={() => this.setState({ modalImages: true, disable1: true })}>Close</LinkButton>
        </div>
      </Dialog>
    )
  }

  renderDetail({ row }) {
    return (
      <Row style={{ padding: "20px" }}>
        <Col>
          <Row>
            <Col>
              <Row><div className="item-desp" style={{ fontWeight: "bold" }}>Judul:</div></Row>
              <Row><div className="item-desp" style={{ paddingLeft: "20px" }}>{row.judul}</div></Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row><div className="item-desp" style={{ fontWeight: "bold" }}>Status:</div></Row>
              <Row><div className="item-desp" style={{ paddingLeft: "20px" }}>{row.status}</div></Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row><div className="item-desp" style={{ fontWeight: "bold" }}>Txt Konten:</div></Row>
              <Row><div className="item-desp" style={{ paddingLeft: "20px", textAlign: "left" }}>{row.text_konten}</div></Row>
            </Col>
          </Row>
          {/* <Row>
            <Col>
                <Button onClick={() => this.editRow(row)}>Image</Button>
            </Col>
          </Row> */}
        </Col>
      </Row>
    )
  }

  handleAdd() {
    if (!this.datagrid.endEdit()) {
      console.log('ter')
      alert('dddd')
      return;

    }
    let data = this.state.data.slice();
    data.unshift({ status: false, _new: true });
    this.setState({ data: data }, () => {
      this.datagrid.beginEdit(data[0])
      console.log('lalaal', data)
    })
  }

  componentDidMount() {
    this.getDataKonten();
  }

  getDataKonten() {
    const url = API_URL.url + '/konten';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ data: data })
        // console.log('tes',data)
        // console.log('lslslsl',this.getData());
      })
  }

  fileSelect1 = event => {
    this.state.selectedFile1 = event.target.files[0];
    this.setState({ disable1: false });
    console.log(this.state);
  }

  fileSelect2 = event => {
    this.state.selectedFile2 = event.target.files[0];
    this.setState({ disable2: false });
    console.log(this.state);
  }

  fileSelect3 = event => {
    this.state.selectedFile3 = event.target.files[0];
    this.setState({ disable3: false });
    console.log(this.state);
  }

  fileSelect4 = event => {
    this.state.selectedFile4 = event.target.files[0];
    this.setState({ disable4: false });
    console.log(this.state);
  }

  fileSelect5 = event => {
    this.state.selectedFile5 = event.target.files[0];
    this.setState({ disable5: false });
    console.log(this.state);
  }

  fileSelect6 = event => {
    this.state.selectedFile6 = event.target.files[0];
    this.setState({ disable6: false });
    console.log(this.state);
  }

  fileUpload1 = () => {
    const row = this.state.model;
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);
    formData.append('pic1', this.state.selectedFile1, this.state.selectedFile1.name);
    axios({
      method: 'POST',
      url: API_URL.url + "/konten/uppic1",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data === "ok") {
        this.getDataKonten();
        this.setState({
          modalImages: true,
          buttonStatus1: 'UPLOAD',
          disable1: false
        })
        this.getDataKonten();
        window.location.reload();
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })
  }

  fileUpload2 = () => {
    const row = this.state.model;
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);
    formData.append('pic2', this.state.selectedFile2, this.state.selectedFile2.name);
    axios({
      method: 'POST',
      url: API_URL.url + "/konten/uppic2",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data === "ok") {
        this.getDataKonten();
        this.setState({
          modalImages: true,
          buttonStatus2: 'UPLOAD',
          disable2: false
        })
        this.getDataKonten();
        window.location.reload();
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })
  }

  fileUpload3 = () => {
    const row = this.state.model;
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);
    formData.append('pic3', this.state.selectedFile3, this.state.selectedFile3.name);
    axios({
      method: 'POST',
      url: API_URL.url + "/konten/uppic3",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data === "ok") {
        this.getDataKonten();
        this.setState({
          modalImages: true,
          buttonStatus3: 'UPLOAD',
          disable3: false
        })
        this.getDataKonten();
        window.location.reload();
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })
  }

  fileUpload4 = () => {
    const row = this.state.model;
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);
    formData.append('pic4', this.state.selectedFile4, this.state.selectedFile4.name);
    axios({
      method: 'POST',
      url: API_URL.url + "/konten/uppic4",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data === "ok") {
        this.getDataKonten();
        this.setState({
          modalImages: true,
          buttonStatus4: 'UPLOAD',
          disable4: false
        })
        this.getDataKonten();
        window.location.reload();
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })
  }

  fileUpload5 = () => {
    const row = this.state.model;
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);
    formData.append('pic5', this.state.selectedFile5, this.state.selectedFile5.name);
    axios({
      method: 'POST',
      url: API_URL.url + "/konten/uppic5",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data === "ok") {
        this.getDataKonten();
        this.setState({
          modalImages: true,
          buttonStatus5: 'UPLOAD',
          disable6: false
        })
        this.getDataKonten();
        window.location.reload();
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })
  }

  fileUpload6 = () => {
    const row = this.state.model;
    const formData = new FormData();
    formData.append('id_konten', row.id_konten);
    formData.append('pic6', this.state.selectedFile6, this.state.selectedFile6.name);
    axios({
      method: 'POST',
      url: API_URL.url + "/konten/uppic6",
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      console.log(reply);
      if (reply.data === "ok") {
        this.getDataKonten();
        this.setState({
          modalImages: true,
          buttonStatus6: 'UPLOAD',
          disable6: false
        })
        this.getDataKonten();
        window.location.reload();
      } else {
        alert('Failed');
      }
    }).catch(function (reply) {
      console.log(reply);
    })
  }

  onFormSubmit1(e) {
    e.preventDefault();
    this.setState({ buttonStatus1: 'LOADING...', disable1: true })
    this.fileUpload1();
  }

  onFormSubmit2(e) {
    e.preventDefault();
    this.setState({ buttonStatus2: 'LOADING...', disable2: true })
    this.fileUpload2();
  }

  onFormSubmit3(e) {
    e.preventDefault();
    this.setState({ buttonStatus3: 'LOADING...', disable3: true })
    this.fileUpload3();
  }

  onFormSubmit4(e) {
    e.preventDefault();
    this.setState({ buttonStatus4: 'LOADING...', disable4: true })
    this.fileUpload4();
  }

  onFormSubmit5(e) {
    e.preventDefault();
    this.setState({ buttonStatus5: 'LOADING...', disable5: true })
    this.fileUpload5();
  }

  onFormSubmit6(e) {
    e.preventDefault();
    this.setState({ buttonStatus6: 'LOADING...', disable6: true })
    this.fileUpload6();
  }


  render() {

    return (
      <>
        <PaymentNavbar />
        <Container>
          <div className="row">
            <div className=" bg-content-3-container" style={{ paddingTop: "0px" }}>
              <h4 className="title1" style={{ fontWeight: 'bold', color: "black" }}>Konsultasi Virtual Setting Panel</h4>
            </div>
          </div>
          <div className="row" style={{ padding: "0px" }}>
            <div className="col" style={{ marginTop: "0px", marginBottom: "30px" }}>
              <div>
                <h2>List Konten</h2>
                <DataGrid
                  ref={ref => this.datagrid = ref}
                  renderDetail={this.renderDetail}
                  style={{ maxHeight: 700 }}
                  data={this.state.data}
                  // clickToEdit
                  editMode="row"
                  toolbar={({ editingItem }) => (
                    <div style={{ padding: 4 }}>
                      <LinkButton iconCls="icon-add" plain onClick={this.AddRow.bind(this)}>Add</LinkButton>
                    </div>
                  )}
                // onEditEnd={this.handleRowEditEnd.bind(this)}
                // onEditCancel={this.handleRowEditCancel.bind(this)}
                >

                  <GridColumn field="rn" align="center" width="30px"
                    cellCss="datagrid-td-rownumber"
                    render={({ rowIndex }) => (
                      <span>{rowIndex + 1}</span>
                    )}
                  />
                  <GridColumn expander width="30px"></GridColumn>
                  {/* <GridColumn field="id_konten" title="Item ID"
                        editable
                        editRules={['required']}
                        editor={({ row, error }) => (
                          <Tooltip content={error} tracking>
                            <TextBox value={row.id_konten}></TextBox>
                          </Tooltip>
                        )}
                      /> */}
                  <GridColumn field="judul" title="Judul" editable></GridColumn>
                  {/* <GridColumn field="text_konten" title="Text Kontent" editable></GridColumn> */}
                  <GridColumn field="status" title="Status" align="center"
                    editable
                    editor={({ row }) => (
                      <CheckBox checked={row.status}></CheckBox>
                    )}
                    render={({ row }) => (
                      <span>{String(row.status)}</span>
                    )}
                  />
                  <GridColumn field="act" title="Actions" align="center" width={200}
                    render={({ row }) => (
                      <div>
                        <ButtonGroup>
                          <LinkButton onClick={() => this.imagesRow(row)}>Images</LinkButton>
                          <LinkButton onClick={() => this.editRow(row)}>Edit</LinkButton>
                          <LinkButton onClick={() => this.deleteRow(row)}>Delete</LinkButton>
                        </ButtonGroup>
                      </div>
                    )}
                  />
                </DataGrid>
                {this.renderDialog()}
                {this.renderDelete()}
                {this.renderModalImages()}
              </div>
            </div>


          </div>
        </Container>
        <DemoFooter />
      </>
    );
  }
}

export default Home;
