import React, { Component } from "react";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import API_URL from  './../../../ApiUrl';
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';

class Register extends Component {
  state = {
    first_name:"",
    no_telp:"",
    email:"",
    code_jenjang:"",
    id_propinsi: "",
    id_kabkota: "",
    address:"",
    code_sekolah_asal: "",
    startDate:new Date(),
    bornDate:"",
    message:"Error !",
    error: {},
    showSwal: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    checkemail:"",
    pesanEmailError:"",
    renderInputSekolah:"none",
    renderInputKet:"none",
    dataProvinsi: [],
    dataJenPen: [],
    dataSekolah: [],
    dataKabKota:[],
    dataKabKotaFilter:[],
  } 

  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //first name
    if(!this.state.first_name){
      formIsValid = false;
      errors.first_name = "First Name is Required";
      this.inputFirstName.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
      formIsValid = false;
      errors.no_telp = "Phone is Required";
      this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
      let lastAtPos  = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
        formIsValid = false;
        errors.email = "Email tidak valid";  
        this.inputEmail.style = this.state.inputBorder;  
      }
    }
    this.setState({error:errors});
    return formIsValid;
  }

  onChangeEmail = (e) => {
    this.setState({email:e.target.value})
    this.checkEmail();   
  }
  setProvinsi = (e) => { 
    const newData = e.target.value;
    const data = Object.values(this.state.dataKabKota);
    const filterData = data.filter(dataFilter => {
      return dataFilter.province_id == newData;
      });
    this.setState({dataKabKotaFilter:filterData})

    this.setState(prevState => {
      let id_propinsi = Object.assign({}, prevState.id_propinsi);  
      id_propinsi = newData; 
      return { id_propinsi };                   
    })
  }
  setSekolahAsal = (e) => {
    const newData = e.length > 0 ? e[0].kode_sekolah : "";
    this.setState(prevState => {
      let code_sekolah_asal = Object.assign({}, prevState.code_sekolah_asal);  
      code_sekolah_asal = newData; 
      return { code_sekolah_asal };                   
    })
  }

  registrationSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
        this.sendData();
    }else{
        this.setState({
            formAlert:{display:'unset'}, 
            formAlertClassName:'alert alert-warning',
            message:"Error !"
        })
    }
  }

  sendData(){
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true,
      showSwal : true
    });

    var formData = new FormData();
    formData.append('name', this.state.first_name)
    formData.append('email', this.state.email)
    formData.append('phone', this.state.no_telp)
    formData.append('code_jenjang', this.state.code_jenjang)
    formData.append('province_id', this.state.id_propinsi)
    formData.append('kabupaten_id',this.state.id_kabkota)
    formData.append('sekolah', this.state.code_sekolah_asal)
    formData.append('id_konten','21')

    axios({
      method: 'post',
      url:API_URL.urlEvent+'/register',
      data:formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.superStudentKey}`
      }
    }).then((reply)=>{
        window.location.reload(false);
    }).catch(function(reply){
        console.log(reply)
    });
  }

  onBlurFirstName(e){
    e.preventDefault()
    if(this.state.first_name.length > 2){
        this.inputFirstName.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.first_name = '';                                 
            return { error };                   
        })
    }
  }
  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }
  onBlurEmail(e){
    e.preventDefault()
    this.checkEmail();
    if(this.state.email.length > 5){
        this.inputEmail.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.email = '';                                 
            return { error };                   
        })
    }
  }
  onBlurJenjang(e){
    e.preventDefault()
    if(this.state.code_jenjang.length > 0){
        this.inputCodeJenPen.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.code_jenjang = '';                                 
            return { error };                   
        })
    }else{
      this.inputCodeJenPen.style = this.state.inputBorder;
      this.setState(prevState => {
          let errors = Object.assign({}, prevState.error);  
          errors.code_jenjang = 'Jenjang Pendidikan Tidak boleh kosong';                                 
          this.setState({error:errors});                  
      })
    }
  }
  onBlurPropinsi(e){
    e.preventDefault()
    if(this.state.id_propinsi.length > 0){
        this.inputIdPropinsi.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_propinsi = '';                                 
            return { error };                   
        })
    }else{
      this.inputIdPropinsi.style = this.state.inputBorder;
      this.setState(prevState => {
          let errors = Object.assign({}, prevState.error);  
          errors.id_propinsi = 'Provinsi Tidak boleh kosong';                                 
          this.setState({error:errors});                  
      })
    }
  }
  onBlurKabKota(e){
    e.preventDefault()
    if(this.state.id_kabkota.length > 0){
        this.inputIdKabKota.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_kabkota = '';                                 
            return { error };                   
        })
    }else{
      this.inputIdKabKota.style = this.state.inputBorder;
      this.setState(prevState => {
          let errors = Object.assign({}, prevState.error);  
          errors.id_kabkota = 'Kabupaten Tidak boleh kosong';                                 
          this.setState({error:errors});                  
      })
    }
  }
  
  checkEmail(){
    const url = API_URL.url+'/peserta/cekmail';

    var formData = new FormData();
    formData.append('email',this.state.email);
    axios({
        method: 'post',
        url: url,
        data:formData,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${API_URL.isMakassarKey}`
        }
        // config:{headers:{'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${API_URL.urlKey}`}}
    }).then(response => response.data)
    .then((data) => {
        this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
        // console.log('cek data email', this.state.checkemail)
        // console.log('cek pesan email', this.state.pesanEmailError)
        console.log('cek email1', this.state.pesanEmailError)
    })
  }

  clearInput() {
    this.inputFirstName.value = "";
    this.inputLastName.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputAddress.value = "";
    this.inputBorndate.value= "";
  }

  componentDidMount() {
    this.getDataJenPen();
    this.getProvinsi();
    this.getKabKota();
  }

  getDataJenPen(){
    const url = API_URL.superStudent+'/master/jenjang';
    axios.get(url,{
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataJenPen: data })
    })
  }

  getProvinsi(){
    const url = API_URL.superStudent+'/master/provinces';
    axios.get(url,{
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataProvinsi: data })
    })
  }

  getKabKota(){
    const url = API_URL.superStudent+'/master/kabupaten';
    axios.get(url,{
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataKabKota: data })
      const id = this.state.id_propinsi;
      if(id > 0){
        const data = Object.values(this.state.dataKabKota);              
        const filterData = data.filter(dataFilter => {
          return dataFilter.province_id == id;
        });
        this.setState({dataKabKotaFilter:filterData})
      }
    })
  }

  getDataSekolah(){
    const url = API_URL.superStudent+'/sekolah-indonesia?province_id='+this.state.id_propinsi;
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.masterKey}`
      }
    })
    .then(response => response.data.data)
    .then((data) => {
      this.setState({ dataSekolah: data })
    })
  }

  render() {
    return (
      <>
        <div className="main">
        <div className="" />
          <div className="section" style={{ backgroundColor: '#101921', color:'gray'}}>
            <Container>
              <Row style={{marginTop:"-70px"}}>
                <Col>
                  <div>
                    <form>
                      <Row>
                        <Col className="ml-auto mr-auto" md="" style={{backgroundColor:"", margin:"0px"}}>
                          <Row>
                            <Col lg="12" md="12">
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label for="first_name" style={{ color: 'gray', fontWeight:'500'}}>Full Name*</Label>
                                <input md="3" className="form-control" type="first_name"  id="first_name" placeholder=""
                                  onChange={(e) => this.setState({first_name:e.target.value})}
                                  onBlur={(e) => this.onBlurFirstName(e)}
                                  ref={e => this.inputFirstName = e}
                                />
                                <label className="error-label-register">
                                  {this.state.error.first_name}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12" md="12">
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label for="no_wa" style={{ color: 'gray', fontWeight:'500'}}>Phone / Whatsapp</Label>
                                <input className="form-control" type="number"  id="no_telp" placeholder=""
                                  onChange={(e) => this.setState({no_telp:e.target.value})}
                                  onBlur={(e) => this.onBlurNoTelp(e)}
                                  ref={e => this.inputNoTelp = e}
                                />
                                <label className="error-label-register">
                                  {this.state.error.no_telp}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label for="inputEmail4" style={{ color: 'gray', fontWeight:'500'}}>Email</Label>
                                <input className="form-control" type="email"  id="inputEmail4" placeholder=""
                                  onChange={this.onChangeEmail} 
                                  onBlur={(e) => this.onBlurEmail(e)}
                                  ref={e => this.inputEmail = e}
                                />
                                <label className="error-label-register">
                                  {this.state.error.email}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label style={{ color: 'gray', fontWeight:'500'}}>Jenjang Pendidikan</Label>
                                <select className="form-control" value={this.state.code_jenjang} name="code_jenjang" type="text" 
                                  onChange={(e) => this.setState({code_jenjang:e.target.value})} 
                                  onBlur={(e) => this.onBlurJenjang(e)}
                                  ref={e => this.inputCodeJenPen = e}
                                >
                                  <option value="0">--Pilih Jenjang--</option>
                                  {this.state.dataJenPen.map((data4)=>(
                                    <option key={data4.code_jenjang} value={data4.code_jenjang}>{data4.jenjang_pendidikan}</option>
                                  ))}
                                </select>
                                <label className="error-label-register">
                                  {this.state.error.code_jenjang}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label style={{ color: 'gray', fontWeight:'500'}}>Propinsi</Label>
                                <select className="form-control" value={this.state.id_propinsi} name="id_propinsi" type="text" 
                                  onChange={this.setProvinsi}
                                  onBlur={(e) => this.onBlurPropinsi(e)}
                                  ref={e => this.inputIdPropinsi = e}
                                >
                                  <option value="0">--Pilih Provinsi--</option>
                                  {this.state.dataProvinsi.map((data2)=>(
                                    <option key={data2.id} value={data2.id}>{data2.province_name}</option>
                                  ))}
                                </select>
                                <label className="error-label-register">
                                  {this.state.error.id_propinsi}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label style={{ color: 'gray', fontWeight:'500'}}>Kabupaten / Kota</Label>
                                <select className="form-control" value={this.state.id_kabkota} name="id_kabkota" type="text"
                                  onChange={(e) => this.setState({id_kabkota:e.target.value}, () => {this.getDataSekolah();})} 
                                  onBlur={(e) => this.onBlurKabKota(e)}
                                  ref={e => this.inputIdKabKota = e}
                                >
                                  <option value="0">--Pilih Kab/Kota--</option>
                                  {this.state.dataKabKotaFilter.map((data3)=>(
                                      <option key={data3.id} value={data3.id}>{data3.kabupaten_name}</option>
                                  ))} 
                                </select>
                              </FormGroup>
                              <label className="error-label-register">
                                {this.state.error.id_kabkota}
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup style={{marginBottom:"0px"}}>
                                <Label style={{ color: 'gray', fontWeight:'500'}}>Sekolah Asal</Label>
                                <Typeahead
                                  id="basic-typeahead"
                                  labelKey="nama_sekolah"
                                  name="code_sekolah_asal"  
                                  options={this.state.dataSekolah}
                                  onChange={(e) => this.setSekolahAsal(e)}
                                  placeholder="--Sekolah Asal--"
                                />
                                <label className="error-label-register">
                                  {this.state.error.code_jenjang}
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row style={{marginTop:"10px", marginBottom:"10px"}}>
                            <Col md="12">
                              <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                                {this.state.message}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Button disabled={this.state.btnDisabled} className="text-center btn btn-danger pull-right" style={{backgroundColor:"#f05a24"}} type="submit"  onClick={e=>this.registrationSubmit(e)}>Submit</Button>
                            </Col>
                          </Row>
                        </Col>
                        <SweetAlert
                          show={this.state.showSwal}
                          title="Selamat Pendaftaran Berhasil"
                          text="Silahkan cek email kamu."
                          icon= 'success'
                          confirmButtonText="Ok."
                        />
                      </Row> 
                    </form>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
