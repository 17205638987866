import React, { Component } from "react";
import axios from 'axios';
import API_URL from './../../../ApiUrl';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FormGroup, Container, Row, Col } from "reactstrap";
import { LandingPageHeader, Register } from './../../../components/molecules/';
import { InlineWidget } from "react-calendly";

class LandingPage extends Component {
  state = {
    nama_lengkap: "",
    no_telp: "",
    email: "",
    id_propinsi: "",
    id_kabkota: "",
    message: "Error !",
    error: {},
    showSwal: false,
    formAlert: { display: 'none' },
    formAlertClassName: 'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataPropinsi: [],
    dataKabkota: [],
    dataKabkotafilter: [],
    aktifKonten: [],
    checkemail: "",
    pesanEmailError: "",
    pilihan:0,
    displayRegister: "flex",
    displayCalendly: "none"
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    //nama
    if (!this.state.nama_lengkap) {
      formIsValid = false;
      errors.nama_lengkap = "Nama wajib diisi";
      this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if (!this.state.no_telp) {
      formIsValid = false;
      errors.no_telp = "No telepon wajib diisi";
      this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if (!this.state.email) {
      formIsValid = false;
      errors.email = "Email wajib diisi";
      this.inputEmail.style = this.state.inputBorder;
    }
    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors.email = "Email tidak valid";
        this.inputEmail.style = this.state.inputBorder;
      }
    }
    if (this.state.checkemail === "Tolak") {
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
    }
    //pripinsi
    if (!this.state.id_propinsi) {
      formIsValid = false;
      errors.id_propinsi = "Provinsi wajib diisi";
      this.inputPropinsi.style = this.state.inputBorder;
    }
    //kabkota
    if (!this.state.id_kabkota) {
      formIsValid = false;
      errors.id_kabkota = "Kabupaten / Kota wajib diisi";
      this.inputKabKota.style = this.state.inputBorder;
    }
    this.setState({ error: errors });
    return formIsValid;
  }

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value })
    this.checkEmail();
  }

  registrationSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.sendData();
    } else {
      this.setState({
        formAlert: { display: 'unset' },
        formAlertClassName: 'alert alert-warning',
        message: "Error !"
      })
    }
  }

  sendData() {
    this.setState({
      message: "Proses simpan, mohon menunggu beberapa saat.",
      formAlert: { display: 'block' },
      formAlertClassName: 'alert alert-warning',
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('nama_lengkap', this.state.nama_lengkap);
    formData.append('no_wa', this.state.no_telp);
    formData.append('email', this.state.email);
    formData.append('id_propinsi', this.state.id_propinsi);
    formData.append('id_kabkota', this.state.id_kabkota);

    axios({
      method: 'post',
      url: API_URL.url + '/peserta/register',
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then((reply) => {
      //console.log(reply)
      if (reply.data[0].id_register !== undefined) {
        this.setState({ showSwal: true })
      } else {
        // alert(reply.data);
        this.setState({ btnDisabled: false })
        // this.hideAlert();
        alert("Pendaftaran Berhasil!")
        window.location.reload();
      }
    }).catch(function (reply) {
      console.log(reply)
    });
  }

  onBlurNamaLengkap(e) {
    e.preventDefault()
    if (this.state.nama_lengkap.length > 2) {
      this.inputNamaLengkap.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.nama_lengkap = '';
        return { error };
      })
    }
  }
  onBlurNoTelp(e) {
    e.preventDefault()
    if (this.state.no_telp.length > 0) {
      this.inputNoTelp.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.no_telp = '';
        return { error };
      })
    } else {
      this.inputNoTelp.style = this.state.inputBorder;
      this.setState(prevState => {
        let errors = Object.assign({}, prevState.error);
        errors.no_telp = 'No. Telp Tidak boleh kosong';
        this.setState({ error: errors });
      })
    }
  }
  onBlurEmail(e) {
    e.preventDefault()
    this.checkEmail();
    if (this.state.email.length > 5) {
      this.inputEmail.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.email = '';
        return { error };
      })
    }
  }
  onBlurPropinsi(e) {
    e.preventDefault()
    if (this.state.id_propinsi.length > 2) {
      this.inputPropinsi.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.id_propinsi = '';
        return { error };
      })
    }
  }
  onBlurKabkota(e) {
    e.preventDefault()
    if (this.state.id_kabkota.length > 2) {
      this.inputKabKota.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);
        error.id_kabkota = '';
        return { error };
      })
    }
  }

  getDataPropinsi() {
    const url = API_URL.master + '/propinsi';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.masterKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ dataPropinsi: data })
        console.log(this.state.dataPropinsi)
      })
  }

  getDataKabkota() {
    const url = API_URL.master + '/kabkota';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.masterKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ dataKabkota: data })
        console.log(this.state.dataKabkota)
      })
  }

  setPropinsi(e) {
    e.preventDefault();
    this.setState({
      id_propinsi: e.target.value
    })

    const data = Object.values(this.state.dataKabkota);
    const id = e.target.value;
    const filterData = data.filter(dataFilter => {
      return dataFilter.id_propinsi == id;
    });
    this.setState({ dataKabkotafilter: filterData })
    console.log(filterData)
  }

  setKabkota(e) {
    e.preventDefault();
    this.setState({
      id_kabkota: e.target.value
    })
    console.log(this.state.id_kabkota)
  }

  radioChangeHandler = (event) => {
    this.setState({
      konsultan: event.target.value
    })
  }

  componentDidMount() {
    // this.getKontenAktif();
    // this.getDataPropinsi();
    // this.getDataKabkota();
  }

  checkEmail() {
    const url = API_URL.url + '/peserta/cekmail';

    var formData = new FormData();
    formData.append('email', this.state.email);
    axios({
      method: 'post',
      url: url,
      data: formData,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
        console.log('cek email1', this.state.pesanEmailError)
      })
  }

  getKontenAktif() {
    const url = API_URL.url + '/konten/aktifkonten';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.isMakassarKey}`
      }
    }).then(response => response.data)
      .then((data) => {
        this.setState({ aktifKonten: data[0] })
        console.log('tes', data[0])
      })
  }

  handleChange = (e) => {
    this.setState({ 
      pilihan:e.target.value 
    }, () => {
      if(this.state.pilihan == 0) {
        this.setState({ displayRegister:'flex', displayCalendly:'none' })
      } else {
        this.setState({ displayRegister:'none', displayCalendly:'flex' })
      }
    })
  }

  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputNamaSekolahlah.value = "";
    this.inputKelasSemester.value = "";
    this.inputPassword.value = "";
    this.selectPropinsi.value = "";
    this.selectKabkota.value = "";
    this.inputRePassword.value = "";
    this.inputKodePromo.value = "";
  }

  render() {

    return (
      <>
        <LandingPageHeader datakonten={this.state.aktifKonten} />
        <div className="main">
          <div className="section" style={{ backgroundColor: '#E5E5E5' }}>
            <div className="mt-5">
              <h2 className="mt-5 mb-5" align="center" style={{ fontWeight: '600' }}>ECU & ECC Exclusive Infosession! </h2>
            </div>
            <div className="mt-5">
              <h4 className="mt-5 mb-2 ml-5" align="left" style={{ fontWeight: '600' }}>Apa sih ECU & ECC itu? </h4>
              <div className="row mb-2 ml-5 mr-5">
                <div className="col-lg-12">
                  <p style={{ fontWeight: '600', textAlign: 'justify' }}>Edith Cowan University (ECU) adalah universitas ternama di Perth Australia, Sebagai sebuah universitas internasional, ECU punya program yang selalu inovatif dan juga praktis yang dikembangkan di dalam berbagai disiplin ilmu, budaya penelitian dibentuk dengan sangat dinamis dan menarik. </p>
                </div>
              </div>
              <div className="row mb-2 ml-5 mr-5">
                <div className="col-lg-12">
                  <p style={{ fontWeight: '600', textAlign: 'justify' }}>Sedangkan Edith Cowan College (ECC), telah dikenal sebagai institut bisnis dan teknologi dan pada saat itu, ECC telah berhasil mensukseskan lebih dari 8.000 siswanya untuk masuk ke ECU. Jadi jika skor IELTS kamu belum cukup gak usah khawatir, bisa mulai di ECC dulu! </p>
                </div>
              </div>
              <div className="row mb-2 ml-5 mr-5">
                <div className="col-lg-12">
                  <p style={{ fontWeight: '600', textAlign: 'justify' }}>Apa aja sih yang dibahas di Info Session ini? Di event ini kamu bisa dapetin informasi tentang beasiswa dan jurusan di ECU & ECC, sampe kerja di Australia! </p>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h2 className="mt-5 mb-5" align="center" style={{ fontWeight: '600' }}>Benefit mengikuti ECU & ECC Exclusive Info Session</h2>
            </div>
            <Col lg="12" md="12">
              <Row className="justify-content-center">
                <Col lg="6" md="6" className="mb-2">
                  <div className="">
                    <img src={require('./../../../assets/img/reason/Beasiswa-01.jpg')}
                      className="responsiveImg"
                      style={{ maxHeight: '190px' }}
                      alt="" />
                  </div>
                </Col>
                <Col lg="6" md="6" className="mb-2">
                  <div className="">
                    <img src={require('./../../../assets/img/reason/Toefl-01.jpg')}
                      className="responsiveImg"
                      style={{ maxHeight: '190px' }}
                      alt="" />
                  </div>
                </Col>
                <Col lg="6" md="6" className="mb-2">
                  <div className="">
                    <img src={require('./../../../assets/img/reason/Mapping-01.jpg')}
                      className="responsiveImg"
                      style={{ maxHeight: '190px' }}
                      alt="" />
                  </div>
                </Col>
                <Col lg="6" md="6" className="mb-2">
                  <div className="">
                    <img src={require('./../../../assets/img/reason/Workshop-01.jpg')}
                      className="responsiveImg"
                      style={{ maxHeight: '190px' }}
                      alt="" />
                  </div>
                </Col>
                <Col lg="6" md="6" className="mb-2">
                  <div className="">
                    <img src={require('./../../../assets/img/reason/student-01.jpg')}
                      className="responsiveImg"
                      style={{ maxHeight: '190px' }}
                      alt="" />
                  </div>
                </Col>
                <Col lg="6" md="6" className="mb-2">
                  <div className="">
                    <img src={require('./../../../assets/img/reason/Merchandise-NIEC-01.jpg')}
                      className="responsiveImg"
                      style={{ maxHeight: '190px' }}
                      alt="" />
                  </div>
                </Col>
              </Row>
            </Col>
          </div>

          <div className="section" style={{ textAlign: 'center' }} align="center">
            <Container>
              <Row>
                <Col lg="2">
                  <img style={{ maxWidth: '250px', maxHeight: '270px' }}
                    alt="..."
                    src={require("../../../assets/img/icon/Icon-01.png")}
                  />
                </Col>
                <Col lg="2">
                  <img style={{ maxWidth: '250px', maxHeight: '270px' }}
                    alt="..."
                    src={require("../../../assets/img/icon/Icon-02.png")}
                  />
                </Col>
                <Col lg="2">
                  <img style={{ maxWidth: '250px', maxHeight: '270px' }}
                    alt="..."
                    src={require("../../../assets/img/icon/Icon-03.png")}
                  />
                </Col>
                <Col lg="3">
                  <img style={{ maxWidth: '250px', maxHeight: '270px' }}
                    alt="..."
                    src={require("../../../assets/img/icon/Icon-05.png")}
                  />
                </Col>
                <Col lg="3">
                  <img style={{ maxWidth: '250px', maxHeight: '270px' }}
                    alt="..."
                    src={require("../../../assets/img/icon/Icon-04.png")}
                  />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section" style={{
            backgroundImage: "url(" + require("../../../assets/img/new-section-03.png") + ")",
            height: '393px',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            color: "white",
            textAlign: "center"
          }}>
          </div>

          <div className="section" style={{ backgroundColor: '#101921', height: '573px', color: 'white', textAlign: 'center' }}>
            <Container>
              <Row>
                <Col md="12" lg="12" className="mb-3">
                  <h2 style={{ fontWeight: '700' }}>
                    Kenapa Harus Kuliah di <br /> Edith Cowan University(ECU)</h2>
                </Col>
              </Row>
              <Row>
                <Col md="3" className="mt-5">
                  <img style={{ maxWidth: '120px', maxHeight: '120px' }}
                    alt="..."
                    className="mb-4"
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-01.png")}
                  />
                  <p className="text-center" style={{ fontWeight: '500' }}>
                    Terbaik #1 di Australia dalam hal kualitas dalam mengajar oleh QILT
                  </p>
                </Col>
                <Col md="3" className="mt-5">
                  <img style={{ maxWidth: '120px', maxHeight: '120px' }}
                    alt="..."
                    className="mb-4"
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-02.png")}
                  />
                  <p className="text-center" style={{ fontWeight: '500' }}>
                    Masuk 100 kampus terbaik di dunia untuk kategori "under 50 years" oleh Times Higher Education
                  </p>
                </Col>
                <Col md="3" className="mt-5">
                  <img style={{ maxWidth: '150px', maxHeight: '150px' }}
                    alt="..."
                    className="mb-5"
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-03.png")}
                  />
                  <p className="text-center" style={{ fontWeight: '500' }}>
                    Predikat bintang 5 dalam hal pengembangan skills & "support student" </p>
                </Col>
                <Col md="3" className="mt-5">
                  <img style={{ maxWidth: '150px', maxHeight: '150px' }}
                    alt="..."
                    className="mb-3"
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/Icon-04.png")}
                  />
                  <p className="text-center" style={{ fontWeight: '500' }}>
                    Bisa mulai di Edith Cowan College jika skor IELTS belum cukup</p>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section" style={{ backgroundColor: 'white', textAlign: 'center' }}>
            <Container>
              <Row>
                <Col md="12" lg="12" className="mb-5">
                  <h2 style={{ fontWeight: '700', color: '#101921' }}>
                    Beberapa Jurusan yang masuk Top Dunia</h2>
                  <h4 style={{ fontWeight: '500' }}>Terdapat ratusan jurusan untuk program S1, S2, S3 dengan program unggulan</h4>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mt-5">
                  <img style={{ maxWidth: '500px', maxHeight: '200px' }}
                    alt="..."
                    className="mb-2"
                    src={require("../../../assets/img/jurusan/bisnis.jpg")}
                  />
                  <h3 className="text-center" style={{ fontWeight: '600' }}>
                    Bisnis & Manajemen
                  </h3>
                </Col>
                <Col md="4" className="mt-5">
                  <img style={{ maxWidth: '500px', maxHeight: '200px' }}
                    alt="..."
                    className="mb-2"
                    src={require("../../../assets/img/jurusan/it.jpg")}
                  />
                  <h3 className="text-center" style={{ fontWeight: '600' }}>
                    IT
                  </h3>
                </Col>
                <Col md="4" className="mt-5">
                  <img style={{ maxWidth: '500px', maxHeight: '200px' }}
                    alt="..."
                    className="mb-2"
                    src={require("../../../assets/img/jurusan/teknik.jpg")}
                  />
                  <h3 className="text-center" style={{ fontWeight: '600' }}>
                    Teknik (Engineering)
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mt-5">
                  <img style={{ maxWidth: '500px', maxHeight: '200px' }}
                    alt="..."
                    className="mb-2"
                    src={require("../../../assets/img/jurusan/psikologi.jpg")}
                  />
                  <h3 className="text-center" style={{ fontWeight: '600' }}>
                    Psikologi
                  </h3>
                </Col>
                <Col md="4" className="mt-5">
                  <img style={{ maxWidth: '500px', maxHeight: '200px' }}
                    alt="..."
                    className="mb-2"
                    src={require("../../../assets/img/jurusan/pendidikan.jpg")}
                  />
                  <h3 className="text-center" style={{ fontWeight: '600' }}>
                    Pendidikan
                  </h3>
                </Col>
                <Col md="4" className="mt-5">
                  <img style={{ maxWidth: '500px', maxHeight: '200px' }}
                    alt="..."
                    className="mb-2"
                    src={require("../../../assets/img/jurusan/kesehatan.jpg")}
                  />
                  <h3 className="text-center" style={{ fontWeight: '600' }}>
                    Kesehatan
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section ml-3" style={{ backgroundColor: '#101921' }}>
            <Container>
              <Row className="mb-5">
                <Col lg="6" md="6" className="justify-content-md-center">
                  <select className="form-control" name="pilihan" onChange={(e) => this.handleChange(e)}>
                    <option value="0">Register Event</option>
                    <option value="1">Consultation Session</option>
                  </select>
                </Col>
              </Row>
              <Row style={{display:this.state.displayRegister}}>
                <Col lg="6" md="6">
                  <Register />
                </Col>
                <Col lg="6" md="6">
                  <h2 style={{ fontWeight: '700', color: 'white', textAlign: 'center' }}>
                    Upcoming Event
                  </h2>
                  <div className="mt-3" align="center">
                    <Calendar
                      value={this.state.value}
                      style={{ maxWidth: '500px', maxHeight: '700px' }}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{display:this.state.displayCalendly}}>
                <Col lg="12" md="12" className="justify-content-md-center">
                  <FormGroup style={{marginBottom:"0px"}}>
                    <InlineWidget url="https://calendly.com/prioritas/ecu-consultation?month=2022-04" />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;
