const url = "http://103.41.205.45:1946";
const master = "http://103.41.205.45:1936";
const membership = "http://103.41.205.45:1936";
const isMakassarKey = 'lsspZ63nEFomZA4NMaEAWAhAoWraoiFM';
const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
const superStudent = 'https://superstudent.niecindonesia.com/api/v1/application';
const urlEvent = 'https://superstudent.niecindonesia.com/event';
const superStudentKey = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ';

const API_URL = {
    url,
    master,
    membership,
    isMakassarKey,
    masterKey,
    superStudent,
    urlEvent,
    superStudentKey
}

export default API_URL;

// export default api_url;